import React, { useCallback, useEffect, useMemo } from "react";
import "./styles.css";
import { Image } from "../Image"
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import ReactMarkdown from "react-markdown";

const Article = ({
    selectedArticle,
    author
}) => {

    const getViews = useCallback(() => {
        if (!selectedArticle) {
          return "0 просмотров"
        }
        const views = selectedArticle.views;
        if (views === null) 
            return "просмотров"
        const views_row = String(views);
        if (views_row[views_row.length - 1] === '1' || views_row.slice(-2) != "11") {
            return views_row + " просмотр";
        } else {
            return views_row + "просмотров";
        }
    }, [selectedArticle])

    const authorAvatar = useMemo(() => (
      author ? author.avatar : ""
    ), [author])

    const authorName = useMemo(() => (
      author ? author.name : ""
    ), [author])

    const authorPosition = useMemo(() => (
      author ? author.position : ""
    ))

    return ( 
        <div className="Article">
            <div className="Article__content">
                <p className="Article__title">{selectedArticle ? selectedArticle.title : ""}</p>
                <div className="Article__under-title">
                    <p className="Article__category">#{selectedArticle ? selectedArticle.category : ""}</p>
                    <p className="Article__createdOn">{selectedArticle ? selectedArticle.createdOn : ""}</p>
                    <p className="Article__views">{getViews()}</p>
                </div>
                <div className="Article__text">
                  {selectedArticle ? selectedArticle.content.map(obj => (
                      <>
                        {/* <p dangerouslySetInnerHTML={{
                          __html: remark().use(recommended).use(remarkHtml).processSync(obj.text).toString()
                        }}></p> */}
                        <ReactMarkdown>
                          {obj.text}
                        </ReactMarkdown>
                        {obj.images.map(image => {
                          return (
                            image ? <Image className="Article__content-image" image={image} /> : ""
                          )
                        })}
                      </>
                    )) : ""}
                </div>
                <div className="Article__author">
                    <div className="Article__author-avatar-container">
                        {authorAvatar ? <Image className="Article__author-avatar" image={authorAvatar} /> : ""}
                    </div>
                    <div className="Article__author-who">
                        <p className="Article__author-name">{authorName}</p>
                        <p className="Article__author-position">{authorPosition}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

Article.getLivePreviewData = ({ data, getAsset }) => ({});

const articleQuery = graphql`
  fragment ArticleFragment on MarkdownRemarkFrontmatter {
    filters {
      categories
    }
    authors {
      name
      position
      avatar {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    articles {
      title
      shortDescription
      createdOn
      views
      category
      slug
      content {
        text
        images {
          childImageSharp {
            fluid(maxWidth: 3000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      image {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      author
    }
  }
`;


export { Article, articleQuery };
