import { graphql } from "gatsby";
import React, { useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { Layout } from "../components/Layout";
import { Article } from "../components/Article";

const ArticlePageTemplate = ({ selectedArticle, slug, author, layout, ...props }) => {
  // const selectedArticle = useMemo(() => (
  //   articles.filter(article => article.slug === slug)
  // )[0], [slug, articles])
  

  // useEffect(() => {
  //   if (window || selectedArticle) {
  //     console.log(selectedArticle)
  //   }
  // })

  return (
    <>
      <Helmet>
        <title>{props.seo.title}</title>
        <meta name="Description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keywords} />
      </Helmet>
      <Article author={author} selectedArticle={selectedArticle}/>
    </>
  );
};

ArticlePageTemplate.getLivePreviewData = (props) => ({
  ...props.data,
  ...Layout.getLivePreviewData(props),
});

const ArticlePage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark;
  const { slug } = pageContext;
  const { articles } = data.markdownRemark.frontmatter;
  const { authors } = data.markdownRemark.frontmatter;
  const selectedArticle = articles.find(article => article.slug === slug)
  // const selectedArticleAuthor = authors.find(author => author.id === selectedArticle.author_id)
  // const selectedArticle = useMemo(() => (
  //   articles.filter(article => article.slug === slug)
  // )[0], [slug, articles])
  const selectedArticleAuthor = useMemo(() => (
    selectedArticle ? authors.filter(author => author.id === selectedArticle.author_id)[0] : null
  ), [selectedArticle])

  return (
    <Layout
      {...data.layout.frontmatter}
      pageId={frontmatter.pageId}
      path={frontmatter.path}
    >
      <ArticlePageTemplate author={selectedArticleAuthor} slug={slug} selectedArticle={selectedArticle} layout={data.layout.frontmatter} {...frontmatter} />
    </Layout>
  );
};

const pageQuery = graphql`
  query ArticlePageTemplate {
    layout: markdownRemark(frontmatter: { meta: { eq: "layout" } }) {
      frontmatter {
        ...LayoutFragment
      }
    }
    markdownRemark(frontmatter: { templateKey: { eq: "blog-page" } }) {
      frontmatter {
        seo {
          title
          description
          keywords
        }

        templateKey
        ...ArticleFragment
      }
    }
  }
`;

export default ArticlePage;

export { pageQuery, ArticlePageTemplate };
